.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.ant-input-disabled, .ant-input[disabled]  {
  color: rgb(0, 0, 0);
    background-color: rgb(247, 247, 248);
    cursor: not-allowed;
    opacity: 1;
}

.ant-picker-input > input[disabled] {
    background: 0px 0px;
    color: rgb(0, 0, 0);
}